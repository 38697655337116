import { call, put } from "redux-saga/effects"
import { IGetAllDeviceData, IGetAllDevicesCount, IGetAllSubscriptionCount, IGetDeviceLocationCount } from "../actions/device-mgmt"
import { getToken } from "../authentication"
import axios from 'axios';

export function* getAllDevicesCount(params: IGetAllDevicesCount) {
  try {
    let { data } = yield call(getAllDevicesCountApi, params)
    yield put({
      type: "STORE_ALL_DEVICE_COUNT",
      payload: data
    })
  } catch (error) {
    console.error("getAllFirmWare", error)
  }
}


export async function getAllDevicesCountApi(params: IGetAllDevicesCount) {
    console.log(params,"shsh")
    const tokenRes = await getToken();
    const res = await axios
      .post(`${process.env.REACT_APP_WEBAPPAPI}/getDeviceDataCount?org_id=${params.payload}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: tokenRes.token!,
          },
        }
      )
      
    return res;
  }



  export function* getAllSubscriptionCount(params: IGetAllSubscriptionCount) {
    try {
      let { data } = yield call(getAllSubscriptionCountApi, params)
      yield put({
        type: "STORE_ALL_SUBSCRIPTION_COUNT",
        payload: data
      })
    } catch (error) {
      console.error("getAllFirmWare", error)
    }
  }
  
  
  export async function getAllSubscriptionCountApi(params: IGetAllSubscriptionCount) {
      console.log(params,"shsh")
      const tokenRes = await getToken();
      const res = await axios
        .post(`${process.env.REACT_APP_WEBAPPAPI}/getSubscriptionData?org_id=${params.payload}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: tokenRes.token!,
            },
          }
        )
        
      return res;
    }

    export function* getAllDeviceData(params: IGetAllDeviceData) {
      try {
        let { data } = yield call(getAllDeviceDataApi, params)
        yield put({
          type: "STORE_ALL_DEVICE_DATA",
          payload: data
        })
      } catch (error) {
        console.error("getAllFirmWare", error)
      }
    }
    
    
    export async function getAllDeviceDataApi(params: IGetAllDeviceData) {
        console.log(params,"shsh")
        const tokenRes = await getToken();
        const response = await axios.get(
          process.env.REACT_APP_WEBAPPAPI + `/getEvOperationLog?org_id=${params.payload}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: tokenRes.token!,
            },
          }
        )
          
          
        return response;
      }


      export function* getAllDeviceLocationCountData(params: IGetDeviceLocationCount) {
        try {
          let { data } = yield call(getAllDeviceLocationCountDataApi, params)
          yield put({
            type: "STORE_ALL_DEVICE_LOCATION_COUNT_DATA",
            payload: data
          })
        } catch (error) {
          console.error("getAllFirmWare", error)
        }
      }
      
      
      export async function getAllDeviceLocationCountDataApi(params: IGetDeviceLocationCount) {
          console.log(params,"shsh")
          const tokenRes = await getToken();
          const res = await axios
          .post(`${process.env.REACT_APP_WEBAPPAPI}/getDeviceLocationCountByState?org_id=${params.payload}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: tokenRes.token!,
              },
            }
          )
          
        return res;
        }
  

