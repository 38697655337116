import { all, call, takeLatest, put, takeEvery } from "redux-saga/effects";
import { Store_UserUpdate } from "./user";
import { IUsersAction } from "../actions/user";
import { Store_AlertUpdate } from "./alert";
import * as Alert from "./alert";
import * as Trends from "./trends";
import { IAlertGraphActions } from "../actions/graph";
import { Store_AlertGraph } from "./graph";
import * as Graphs from "./graph";
import * as ChargingStation from "./chargingStation";
import {
  Store_AlertInsights,
  getAlertInsight,
  postAlertClearanceComment,
  getPastAlertGraphData,
  getPastAlertData,
  Store_PastAlert,
  updatePastAlertData,
  getSingleAlertDetail,
  Store_UpdateSingleAlert,
  postClearAlertGraph,
} from "./alert-detail";
import {
  IAlertDetailActions,
  IPastAlertDetailActions,
  ISingleAlertDetailAction,
  IClearGraphActions,
} from "../actions/alert-detail";
import {
  getBatteryListByStationIdFromPayload,
  getBookingDeatilsIdFromPayload,
  getRidersListByName,
  getRidersListFromPayload,
  getSlotDistributionOveriew,
  getStationDetailsByIdFromPayload,
  getSwapByStationIdFromPayload,
  getSwapByUserIdFromPayload,
  getSwappingStationList,
  getSwappingStationListByName,
  getTotalBatteriesCountWithParams,
  getTotalConsumersCountWithParams,
  getTotalStationsCountWithParams,
  getTotalSwapsCountWithParams,
  getUsageOveriew,
} from "./swappingStation";
import {
  addNewIotDevice,
  assignFirmwareToOrg,
  deleteFirmware,
  dropdownOrg,
  firmwareListDropdown,
  getALlJobs,
  getAllAssignedFirmware,
  getAllFirmWare,
  getAllIotDevice,
  initiateOta,
  searchIotDevice,
  unAssignFirmwareToOrg,
  uploadFirmware,
  deleteIotDevices,
  deleteIotJobs,
  getJobDetailsByJobId,
  initiateOtaBulk,
  bulkDeviceRegistration,
  createThingGroup,
  addThingToThingGroup,
  getAllThingGroups,
  getThingGroupDetailsByName,
  searchAllThingGroup,
  getAllDevices,
  getOTAHomePageDataFromParams,
  deleteIotDeviceGroups,
  getDeviceGroupDetailsByDeviceGroupId,
  getAllFilterDeviceDropdownValues,
  addDevicesToGroup,
  getDevicesFromGroup,
  deleteDevice,
  getAllDeviceGroups,
  createDeviceGroup,
  getAllFilterDevices,
} from "./device-control";
import {
  GetReservationDetails,
  GetTransactionDetails,
  addNewChargeBoxDetails,
  addNewChargingProfileData,
  addNewChargingSchedule,
  addnewOcppTagData,
  getChargeBoxDetails,
  getChargingProfileData,
  getChargingStationById,
  getChargingStationDetails,
  getPaymentConfigurationDetails,
  addRate,
  getOCPPTagData,
  getOcppTagDatabyId,
  getchargePointDataById,
  getchargingProfileDataById,
  getchargingschedulebyprofileId,
  storeConnectorDetails,
  updateChargeboxDataById,
  updateChargingDetailsById,
  updateOcppDetailsById,
  updatePaymentConfigurationDetailsById,
  // updateSchedulePeriodData,
  updatechargeProfileDataById,
  updateSchedulePeriodData,
  storeChargingSummaryDetails,
  getChargeControllerCommandData,
  GetTransactionBulkDetails,
  getChargingStationDetailsBySearchName,
  getOCPPTagSearchData,
  getTransactionMeterValuesByID,
} from "./chargingStation/index";

import { TAlertInsights, TPastAlert, TAlertClear } from "../redux/models";
import { getQuickSightUrl, clearQuickSightUrl } from "./quickSight";
import * as Dashboard from "./dashboard";
import * as CmdService from "./cmd-details";
import * as Map from "./map";
import * as Search from "./search";
import {
  getBatteryDetailsChartApi,
  getTripDetailsChartApi,
  getVehicleDetailsChartApi,
} from "./b2b-charts";
import { getAllDeviceData, getAllDeviceLocationCountData, getAllDevicesCount, getAllSubscriptionCount } from "./device-mgmt";
export const yantraBaseUrl =
  "https://a15um0si4f.execute-api.us-east-2.amazonaws.com/yantra-dynamic";

function* getAlertInsights(params: IAlertDetailActions) {
  try {
    const data: TAlertInsights = yield call(getAlertInsight, params);
    yield put({
      type: "STORE_ALERTS_INSIGHTS",
      payload: {
        alertInsight: data,
      },
    } as Store_AlertInsights);
  } catch (error) {
    console.log("get Alerts error", error);
  }
}

function* postAlertClearance(param: IAlertDetailActions) {
  try {
    const data: TAlertClear = yield call(postAlertClearanceComment, param);
    yield put({
      type: "CLEAR_ALERT_REDUCER",
      payload: {
        alertId: param.payload.alertId,
        didAlertClear: data.status,
      },
    });
  } catch (error) {
    console.log("error", error);
  }
}

function* clearAlertGraph(params: IClearGraphActions) {
  try {
    const alertTypeId = yield call(postClearAlertGraph, params);
    yield put({
      type: "STORE_ALERT_GRAPH",
      payload: {
        alertTypeId: alertTypeId,
        data: [],
      },
    });
  } catch (error) {
    console.log("error", error);
  }
}

function* getPastAlertDatas(param: IAlertDetailActions) {
  try {
    const data: TPastAlert = yield call(getPastAlertData, param);
    yield put({
      type: "STORE_PAST_ALERTS",
      payload: {
        pastAlert: data,
        pagination: param.payload.pagination,
        sort: param.payload.sort,
      },
    } as Store_PastAlert);
  } catch (error) {
    console.log("error", error);
  }
}

function* updatePastAlertDatas(params: IPastAlertDetailActions) {
  yield call(updatePastAlertData, params); //
}

function* getPastAlertGraphDatas(params: IAlertGraphActions) {
  try {
    const data = yield call(getPastAlertGraphData, params);
    yield put({
      type: "STORE_ALERT_GRAPH",
      payload: {
        alertTypeId: params.payload.alertTypeId,
        data: data,
      },
    } as Store_AlertGraph);
  } catch (error) {
    console.log("error", error);
  }
}

function* getSingleAlertDetails(params: ISingleAlertDetailAction) {
  try {
    const data = yield call(getSingleAlertDetail, params);
    yield put({
      type: "STORE_UPDATE_SINGLE_ALERT",
      payload: {
        alertData: data,
        alertId: params.payload.alertId,
        alertType: params.payload.alertType,
      },
    } as Store_UpdateSingleAlert);
  } catch (error) {
    console.log("error", error);
  }
}

function* resetAlertDataStore(params: IAlertDetailActions) {
  yield put({
    type: "STORE_ALERT_UPDATE",
    payload: {
      alertType: params.payload.alertType,
      alerts: {
        bms: { data: [], dataCount: 0 },
        mc: { data: [], dataCount: 0 },
        smart: { data: [], dataCount: 0 },
        vcu: { data: [], dataCount: 0 },
        charger: { data: [], dataCount: 0 },
      },
      pagination: params.payload.pagination,
      sort: params.payload.sort,
      alertPagination: {
        bms: { pageNumber: 1, pageSize: 10 },
        smart: { pageNumber: 1, pageSize: 10 },
        mc: { pageNumber: 1, pageSize: 10 },
        vcu: { pageNumber: 1, pageSize: 10 },
        charger: { pageNumber: 1, pageSize: 10 },
      },
    },
  } as Store_AlertUpdate);
}

function* updateUser(params: IUsersAction) {
  yield put({
    type: "STORE_USER_UPDATE",
    payload: {
      authenticated: params.payload.authenticated,
      user: params.payload.user,
    },
  } as Store_UserUpdate);
}

function* actionWatcher() {
  yield takeLatest("GET_ALERTS", Alert.getAlertData);
  yield takeLatest("GET_ALERTS_DOWNLOAD", Alert.getAlertDataDownload);
  yield takeLatest("GET_ALERTS_COUNT", Alert.getAlertDataCount);
  yield takeLatest("UPDATE_ACTIVE_ALERT", Alert.updateAlertTabChange);
  yield takeLatest("UPDATE_FILTER", Alert.updateAlertFilterChange);
  yield takeLatest("GET_DROPDOWN_FILTERS", Alert.getDropdownFilterOptions);
  yield takeLatest(
    "GET_FILTERED_ALERTS_COUNT",
    Alert.getFilteredAlertDataCount
  );
  yield takeLatest("GET_ALERT_TRENDS", Trends.getAlertTrend);
  yield takeLatest("GET_ALERTS_INSIGHTS", getAlertInsights);
  yield takeLatest("POST_ALERT_CLEARANCE", postAlertClearance);
  //b2b charts saga
  yield takeLatest("GET_VEHICLE_INSIGHTS_CHARTS", getVehicleDetailsChartApi);
  yield takeLatest("GET_TRIP_INSIGHTS_CHARTS", getTripDetailsChartApi);
  yield takeLatest("GET_BATTERY_INSIGHTS_CHARTS", getBatteryDetailsChartApi);
  // yield takeLatest("GET_LOW_MILEAGE", getLowMileageGraph);
  // yield takeLatest("GET_VEHICLE_USAGE", getVehicleUsageGraph);
  yield takeLatest("GET_PAST_ALERTS", getPastAlertDatas);
  yield takeLatest("UPDATE_PAST_ALERTS", updatePastAlertDatas);
  yield takeLatest("UPDATE_ALERT_TRENDS", Trends.updateAlertTrend);
  yield takeLatest("GET_ALERT_GRAPH", Graphs.getAlertGraphDatas);
  yield takeLatest("CLEAR_ALERT_GRAPH_DATA", Graphs.clearAlertGraphDatas);
  yield takeLatest("GET_PAST_ALERT_GRAPH", getPastAlertGraphDatas);
  yield takeLatest("GET_SINGLE_ALERT", getSingleAlertDetails);
  yield takeLatest("RESET_ALERT_MAIN_PAGE", resetAlertDataStore);
  yield takeLatest("CLEAR_ALERT_GRAPH", clearAlertGraph);
  yield takeLatest("UPDATE_USER", updateUser);
  yield takeLatest("GET_QUICKSIGHT_EMBED_URL", getQuickSightUrl);
  yield takeLatest("GET_DASHBOARD_LIST", Dashboard.getDashboardList);
  yield takeLatest("CLEAR_DASHBOARD_LIST", Dashboard.clearDashboardList);
  yield takeLatest("CLEAR_QUICKSIGHT_EMBED_URL", clearQuickSightUrl);
  yield takeLatest("GET_MAP_MARKERS", Map.getMapMarkers);
  yield takeLatest("GET_MAPVIEW_DROPDOWN_FILTERS", Map.getMapViewFilters);
  yield takeLatest("GET_SEARCH_OPTIONS", Search.getSearchOptions);
  yield takeLatest("GET_MODULES", CmdService.getModules);
  yield takeLatest("GET_SUB_MODULES", CmdService.getSubModules);
  yield takeEvery("SUBMIT_COMPONENT_DATA", CmdService.submitData);
  yield takeLatest("GET_COMPONENT_DATA", CmdService.getComponentData);
  // yield takeLatest("GET_CS_SUBCOMPONENTS",ChargingStation.getCsSubComponentData);
  // yield takeLatest("GET_CS_GRID_DATA",ChargingStation.getCsGridDatas);
  // yield takeLatest("GET_CS_GRID_COLUMN",ChargingStation.getCsGridColumns);
  // yield takeLatest("GET_CS_LOCATION_DROPDOWN",ChargingStation.getcsLocations);
  // yield takeLatest("INSERT_NEW_STATION",ChargingStation.insertNewSations)
  // yield takeLatest("UPDATE_CHARGING_STATION",ChargingStation.updateChargingStation)
  // yield takeLatest("GET_CS_MODULES",ChargingStation.getCsModuleData)
  // yield takeLatest("GET_CS_MODULE_CHANGE",ChargingStation.getCsModuleDataChange)
  // yield takeLatest("GET_CS_GRID_DATA_SOURCE",ChargingStation.getCsGridDataSource)
  // yield takeLatest("GET_CS_WEB_COMPONENTS",ChargingStation.getCsWebComponentData)
  // yield takeLatest("FILTER_GRID_DATA",ChargingStation.filterchargingStation)
  // yield takeLatest("GET_STATION_ADD_FORM",ChargingStation.getChargingStationForm)
  yield takeEvery("DROP_DOWN", CmdService.getDropdownComponentData);
  yield takeLatest("GRID", CmdService.getComponentData);
  yield takeEvery("GRID_WIDGET", CmdService.getWidgetComponentData);
  yield takeEvery(
    "GRID_STATUS_WIDGET",
    CmdService.getStatusWidgetComponentData
  );
  yield takeEvery(
    "DESCRIPTION_WIDGET",
    CmdService.getDescriptionWidgetComponentData
  );
  yield takeLatest(
    "GRID_LIST_WIDGET",
    CmdService.getDescriptionWidgetComponentData
  );
  yield takeEvery("EDIT_WIDGET", CmdService.getDescriptionWidgetComponentData);
  yield takeEvery("DASHBOARD_CARD", CmdService.getQsDashboardData);
  yield takeEvery(
    "CLEAR_MODULE_COMPONENTS",
    CmdService.clearmoduleComponentData
  );

  yield takeLatest(
    "GET_OVERVIEW_COUNT_STATIONS",
    getTotalStationsCountWithParams
  );
  yield takeLatest(
    "GET_OVERVIEW_COUNT_BATTERIES",
    getTotalBatteriesCountWithParams
  );
  yield takeLatest(
    "GET_OVERVIEW_COUNT_CONSUMERS",
    getTotalConsumersCountWithParams
  );
  yield takeLatest("GET_OVERVIEW_COUNT_SWAPS", getTotalSwapsCountWithParams);
  yield takeLatest("GET_SWAPPING_STATION_LISTS", getSwappingStationList);
  // for charts
  yield takeLatest("GET_USAGE_OVERVIEW", getUsageOveriew);
  yield takeLatest("GET_SLOT_DISTRIBUTION", getSlotDistributionOveriew);
  // get station details by id
  yield takeLatest(
    "GET_STATION_DETAILS_BY_ID",
    getStationDetailsByIdFromPayload
  );
  yield takeLatest(
    "GET_BATTERY_LIST_BY_STATION_ID",
    getBatteryListByStationIdFromPayload
  );
  yield takeLatest(
    "GET_SWAP_HISTORY_BY_STATION_ID",
    getSwapByStationIdFromPayload
  );
  yield takeLatest(
    "GET_BOOKING_DETAILS_BY_STATION_ID",
    getBookingDeatilsIdFromPayload
  );
  yield takeLatest("GET_RIDERS_LIST", getRidersListFromPayload);
  // get station by name
  yield takeLatest(
    "SWAPPING_STATION_SEARCH_KEYWORD",
    getSwappingStationListByName
  );
  // get riders by name
  yield takeLatest(
    "SWAPPING_STATION_RIDERS_SEARCH_KEYWORD",
    getRidersListByName
  );
  // get swap history by user id
  yield takeLatest("GET_SWAP_HISTORY_BY_USER_ID", getSwapByUserIdFromPayload);

  /*Device Control Management */
  yield takeEvery("GET_ALL_FIRMWARE", getAllFirmWare);
  yield takeEvery("UPLOAD_FIRMWARE", uploadFirmware);
  yield takeEvery("DELETE_FIRMWARE", deleteFirmware);
  yield takeEvery("DROPDOWN_ORG", dropdownOrg);
  yield takeEvery("ASSIGN_FIRMWARE_TO_ORG", assignFirmwareToOrg);
  yield takeEvery("GET_ALL_ASSIGNED_FIRMWARE", getAllAssignedFirmware);
  yield takeEvery("UNASSIGN_FIRMWARE_FROM_ORG", unAssignFirmwareToOrg);
  yield takeEvery("GET_ALL_IOT_DEVICES", getAllIotDevice);
  yield takeEvery("SEARCH_IOT_DEVICES", searchIotDevice);
  yield takeEvery("ADD_NEW_IOT_DEVICE", addNewIotDevice);
  yield takeEvery("FIRMWARE_LIST_DROPDOWN", firmwareListDropdown);
  yield takeEvery("INITIATE_OTA", initiateOta);
  yield takeEvery("GET_ALL_JOBS", getALlJobs);
  yield takeEvery("DELETE_IOT_DEVICES", deleteIotDevices);
  yield takeEvery("DELETE_IOT_JOBS", deleteIotJobs);
  yield takeEvery("GET_JOB_DETAILS_BY_JOB_ID", getJobDetailsByJobId);
  yield takeEvery("INITIATE_OTA_BULK", initiateOtaBulk);
  yield takeEvery("BULK_DEVICE_REGISTRATION", bulkDeviceRegistration);
  yield takeEvery("CREATE_THING_GROUP", createThingGroup);
  yield takeEvery("ADD_THING_TO_THING_GROUP", addThingToThingGroup);
  yield takeEvery("GET_ALL_THING_GROUPS", getAllThingGroups);
  yield takeEvery(
    "GET_THING_GROUP_DETAILS_BY_NAME",
    getThingGroupDetailsByName
  );
  yield takeEvery("SEARCH_ALL_THING_GROUP", searchAllThingGroup);
  yield takeEvery("GET_OTA_HOME_PAGE_DATA", getOTAHomePageDataFromParams);

  yield takeEvery("GET_ALL_DEVICE_GROUPS", getAllDeviceGroups);
  yield takeEvery("DELETE_IOT_DEVICE_GROUPS", deleteIotDeviceGroups);
  yield takeEvery(
    "GET_DEVICE_GROUP_DETAILS_BY_DEVICE_GROUP_ID",
    getDeviceGroupDetailsByDeviceGroupId
  );
  yield takeEvery("CREATE_DEVICE_GROUP", createDeviceGroup);
  yield takeEvery("GET_ALL_FILTER_DEVICES", getAllFilterDevices);
  yield takeEvery(
    "GET_ALL_FILTER_DEVICES_DROPDOWN",
    getAllFilterDeviceDropdownValues
  );
  yield takeEvery("ADD_DEVICE_TO_GROUP", addDevicesToGroup);
  yield takeEvery("GET_DEVICE_FROM_GROUP", getDevicesFromGroup);
  yield takeEvery("DELETE_DEVICE", deleteDevice);

  // //charging station
  yield takeEvery("GET_CHARGING_STATION_DETAILS", getChargingStationDetails);
  yield takeEvery("GET_PAYMENT_CONFIGURATION_DETAILS" , getPaymentConfigurationDetails)
  yield takeEvery("ADD_RATE", addRate);
  yield takeEvery("GET_CHARGING_STATION_DETAILS_BY_ID", getChargingStationById);
  yield takeEvery(
    "UPDATE_CHARGING_STATION_DETAILS_BY_ID",
    updateChargingDetailsById
  );
  // yield takeEvery("STORE_NEW_CHARGING_STATION_DETAILS", ChargingStation.AddNewStation);

  yield takeEvery("GET_OCPP_TAG_DETAILS", getOCPPTagData);
  yield takeEvery("ADD_OCPP_TAG_DETAILS", addnewOcppTagData);
  yield takeLatest("GET_OCPP_TAG_DETAILS_BY_ID", getOcppTagDatabyId);
  yield takeLatest("UPDATE_OCPP_TAG_DETAILS_BY_ID", updateOcppDetailsById);
  yield takeLatest("UPDATE_PAYMENT_CONFIGURATION_BY_ID",updatePaymentConfigurationDetailsById);
  yield takeEvery("SEARCH_OCPP_TAG_BY_TAGID", getOCPPTagSearchData);

  yield takeEvery("GET_TRANSACTION_DATA", GetTransactionDetails);
  yield takeEvery("GET_BULK_TRANSACTION_DATA", GetTransactionBulkDetails);
  yield takeEvery("GET_RESERVATION_DATA", GetReservationDetails);

  yield takeLatest("GET_CHARGING_PROFILE_DETAILS", getChargingProfileData);
  yield takeEvery("ADD_CHARGING_PROFILE_DETAILS", addNewChargingProfileData);
  yield takeLatest(
    "GET_CHARGING_PROFILE_DETAILS_BY_ID",
    getchargingProfileDataById
  );
  yield takeLatest(
    "UPDATE_CHARGING_PROFILE_DETAILS_BY_ID",
    updatechargeProfileDataById
  );
  yield takeLatest("GET_CONNECTOR_DETAILS", storeConnectorDetails);
  yield takeLatest(
    "GET_SCHEDULE_PERIOD_DETAILS_BY_PROFILE_ID",
    getchargingschedulebyprofileId
  );
  yield takeLatest(
    "UPDATE_SCHEDULE_PERIOD_DETAILS_BY_PROFILE_ID",
    updateSchedulePeriodData
  );

  yield takeLatest("GET_CHARGE_POINT_DETAILS", getChargeBoxDetails);
  yield takeLatest("ADD_NEW_CHARGE_POINT_DETAILS", addNewChargeBoxDetails);
  yield takeLatest("GET_CHARGE_POINT_DETAIS_BY_ID", getchargePointDataById);
  yield takeLatest("UPDATE_CHARGE_POINT_DETAIS_BY_ID", updateChargeboxDataById);

  yield takeLatest("ADD_SCHEDULED_PERIOD_DETAILS", addNewChargingSchedule);
  yield takeEvery("GET_CHARGING_SUMMARY_DETAILS", storeChargingSummaryDetails);
  yield takeEvery(
    "GET_CHARGING_CONTROLLER_COMMANDS",
    getChargeControllerCommandData
  );
  yield takeEvery(
    "CHARGING_STATION_SEARCH_KEYWORD",
    getChargingStationDetailsBySearchName
  );
  yield takeEvery(
    "GET_TRANSACTION_METER_VALUES",
    getTransactionMeterValuesByID
  );
  //role_mgmt
  // yield takeEvery("GET_ALL_ROLES_AND_MODULES", getAllRolesandModules);
  //devicemgmtnew
  yield takeEvery("GET_ALL_DEVICE_COUNT", getAllDevicesCount);
  yield takeEvery("GET_ALL_SUBSCRIPTION_COUNT", getAllSubscriptionCount);
  yield takeEvery("GET_ALL_DEVICE_DATA", getAllDeviceData);
  yield takeEvery("GET_ALL_DEVICE_LOCATION_COUNT_DATA", getAllDeviceLocationCountData);
}

export default function* rootSaga() {
  yield all([actionWatcher()]);
}
